import React, { FunctionComponent } from 'react'

import firebase from 'firebase/app'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'

import * as api from '../utils/api'
import { unpackChangeEventValue } from '../utils/helpers'
import { domainIsValid } from '../utils/names'
import {
  CHANGE_DOMAIN_ALIAS_EVENT_TYPE,
  DomainAlias,
  DomainAssociationStatus,
} from '../utils/types'

function createDefaultDomainAlias(): DomainAlias {
  return {
    status: DomainAssociationStatus.unassociated,
    project: '',
    domain: '',
    last_status_update_time: new Date(Date.now()).toISOString(),
  }
}

const CustomDomainManagerController = ({
  firebaseApp,
  domainAlias,
  projectId,
  onChangeDomainAlias,
  onRefreshDomainAliasProposal,
}: CustomDomainManagerProps) => {
  // Model

  const [newDomainAlias, updateNewDomainAlias] = React.useReducer(
    (state: DomainAlias, update: Partial<DomainAlias>) => {
      return {
        ...state,
        ...update,
      }
    },
    ((): DomainAlias => {
      const newDomainAlias = domainAlias
        ? { ...domainAlias }
        : { ...createDefaultDomainAlias(), project: `projects/${projectId}` }
      return newDomainAlias
    })(),
  )

  React.useEffect(() => {
    if (domainAlias !== undefined) {
      updateNewDomainAlias(domainAlias)
    }
    setComponentLoaded(true)
  }, [domainAlias])

  // State
  const [newDomainStringError, setNewDomainStringError] = React.useState<
    string | undefined
  >(undefined)
  React.useEffect(() => {
    if (domainIsValid(newDomainAlias.domain)) {
      setNewDomainStringError(undefined)
    } else {
      // TODO(alex): provide more useful errors
      setNewDomainStringError('Domain string is invalid')
    }
  }, [newDomainAlias])

  const [formIsValid, setFormIsValid] = React.useState(true)
  React.useEffect(() => {
    setFormIsValid(!newDomainStringError)
  }, [newDomainStringError])

  const [componentLoaded, setComponentLoaded] = React.useState(false)
  const [currentlyCheckingAliasStatus, setCurrentlyCheckingAliasStatus] =
    React.useState(false)
  const [currentlyEditing, setCurrentlyEditing] = React.useState(false)
  const [currentlySaving, setCurrentlySaving] = React.useState(false)
  const [currentlyRenewing, setCurrentlyRenewing] = React.useState(false)

  // Actions
  const saveDomainAlias = async (alias: DomainAlias) => {
    setCurrentlySaving(true)

    await onChangeDomainAlias(
      new CustomEvent(CHANGE_DOMAIN_ALIAS_EVENT_TYPE, {
        detail: alias,
      }),
    )
    setCurrentlySaving(false)
  }

  const refreshDomainAliasProposal = async (alias: DomainAlias | undefined) => {
    if (alias === undefined) {
      console.error('Attempted to refresh an undefined domain alias.')
      return
    }

    setCurrentlyRenewing(true)
    await onRefreshDomainAliasProposal(
      new CustomEvent(CHANGE_DOMAIN_ALIAS_EVENT_TYPE, {
        detail: alias,
      }),
    )
    setCurrentlyRenewing(false)
  }

  async function checkAliasStatus() {
    if (domainAlias?.domain === undefined) {
      console.error('checkAliasStatus called before domain field was set')
      return
    }

    setCurrentlyCheckingAliasStatus(true)
    let newStatus = undefined
    try {
      newStatus = await api.getCurrentAliasStatus(
        firebaseApp,
        domainAlias.domain,
        projectId,
      )
    } catch (error) {
      // TODO(alex): Communicate this failure to the patron
      console.error('Checking alias status failed with error:', error)
    }

    if (newStatus !== undefined && newStatus !== domainAlias.status) {
      await saveDomainAlias({
        ...domainAlias,
        status: newStatus,
      })
    }
    setCurrentlyCheckingAliasStatus(false)
  }

  return {
    model: {
      domainAlias,
      newDomainAlias,
      projectId,
    },

    state: {
      componentLoaded,
      currentlyCheckingAliasStatus,
      currentlyEditing,
      currentlyRenewing,
      currentlySaving,
      newDomainStringError,
      formIsValid,
    },

    actions: {
      startEditing: () => setCurrentlyEditing(true),
      finishEditing: () => setCurrentlyEditing(false),
      saveDomainAlias,
      updateNewDomainAlias,
      checkAliasStatus,
      refreshDomainAliasProposal,
    },
  }
}

type CustomDomainManagerProps = {
  firebaseApp: firebase.FirebaseApp
  projectId: string
  domainAlias?: DomainAlias
  onChangeDomainAlias: (event: CustomEvent<DomainAlias>) => Promise<void>
  onRefreshDomainAliasProposal: (
    event: CustomEvent<DomainAlias>,
  ) => Promise<void>
}
const CustomDomainManager: FunctionComponent<CustomDomainManagerProps> = (
  props,
) => {
  const c = CustomDomainManagerController(props)

  const cssClasses = makeStyles({
    customDomainCard: {
      width: '100%',
    },
    customDomainCardTitle: {
      display: 'flex',
      'align-items': 'center',
    },
    cancelButton: {
      'background-color': 'white',
    },
    createNewAliasButton: {
      'background-color': 'white',
    },
  })()

  // TODO(alex): Disable save button when editor form is invalid
  return (
    <>
      {!c.state.componentLoaded ? (
        <CircularProgress />
      ) : (
        <Card
          className={cssClasses.customDomainCard}
          elevation={c.state.currentlyEditing ? 10 : 0}>
          <CardHeader
            action={
              <IconButton
                aria-label="edit"
                disabled={c.state.currentlyEditing}
                onClick={c.actions.startEditing}>
                <EditIcon />
              </IconButton>
            }
            title={
              c.state.currentlyEditing ? 'Editing Domain Alias' : 'Domain Alias'
            }
          />

          <CardContent>
            {c.state.currentlySaving ? (
              <>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={'auto'}>
                    <Typography>Saving Domain Alias</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={'auto'}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {c.state.currentlyEditing ? (
                  <DomainAliasEditor
                    alias={c.model.newDomainAlias}
                    domainStringError={c.state.newDomainStringError}
                    onChangeDomainAlias={c.actions.updateNewDomainAlias}
                  />
                ) : (
                  <>
                    {c.model.domainAlias === undefined ? (
                      <Typography>
                        No domain alias has been created yet. Would you like to
                        <Button
                          className={cssClasses.createNewAliasButton}
                          color="primary"
                          onClick={c.actions.startEditing}>
                          create a new domain alias
                        </Button>
                        ?
                      </Typography>
                    ) : (
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <DomainAliasDisplay
                            association={c.model.domainAlias}
                          />
                        </Grid>
                        {c.model.domainAlias.status ===
                        DomainAssociationStatus.associated ? (
                          <></>
                        ) : (
                          <>
                            <Grid item xs={12}>
                              <DomainVerificationRecordGenerator
                                disabled={
                                  c.state.currentlyCheckingAliasStatus ||
                                  c.model.newDomainAlias.status !==
                                    DomainAssociationStatus.pending
                                }
                                firebaseApp={props.firebaseApp}
                                projectId={c.model.projectId}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {c.model.domainAlias.status !==
                              DomainAssociationStatus.pending ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  disabled={c.state.currentlyRenewing}
                                  onClick={async () => {
                                    await c.actions.refreshDomainAliasProposal(
                                      c.model.domainAlias,
                                    )
                                  }}>
                                  {c.state.currentlyRenewing ? (
                                    <>
                                      Refreshing &nbsp;
                                      <CircularProgress size={24} />
                                    </>
                                  ) : (
                                    <>Refresh domain alias proposal</>
                                  )}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  disabled={
                                    c.state.currentlyCheckingAliasStatus
                                  }
                                  onClick={c.actions.checkAliasStatus}>
                                  {c.state.currentlyCheckingAliasStatus ? (
                                    <>
                                      Checking &nbsp;
                                      <CircularProgress size={24} />
                                    </>
                                  ) : (
                                    <>Check verification status</>
                                  )}
                                </Button>
                              )}
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Card
                            className={cssClasses.customDomainCard}
                            elevation={0}>
                            <CardContent>
                              <DomainConfigurationInfoPanel
                                alias={c.model.domainAlias}
                              />
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </CardContent>

          {c.state.currentlyEditing ? (
            <Grow
              in={c.state.currentlyEditing}
              style={{ transformOrigin: '0 0 0' }}>
              <CardActions>
                <Button
                  className={cssClasses.cancelButton}
                  variant="contained"
                  disabled={c.state.currentlySaving}
                  onClick={c.actions.finishEditing}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={c.state.currentlySaving || !c.state.formIsValid}
                  onClick={async () => {
                    await c.actions.saveDomainAlias(c.model.newDomainAlias)
                    c.actions.finishEditing()
                  }}>
                  Save
                </Button>
              </CardActions>
            </Grow>
          ) : (
            <></>
          )}
        </Card>
      )}
    </>
  )
}

type DomainAliasDisplayProps = {
  association: DomainAlias
}
const DomainAliasDisplay: FunctionComponent<DomainAliasDisplayProps> = ({
  association,
}) => {
  // TODO(alex): Improve the UI for communicating the status.
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextField
          id="domainString"
          label="Domain"
          InputProps={{
            readOnly: true,
          }}
          value={association.domain}
          margin="normal"
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="associationStatus"
          label="Verification status"
          InputProps={{
            readOnly: true,
          }}
          value={association.status}
          margin="normal"
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
}

type DomainAliasEditorProps = {
  alias: DomainAlias
  domainStringError?: string
  onChangeDomainAlias: (alias: DomainAlias) => void
}
const DomainAliasEditor: FunctionComponent<DomainAliasEditorProps> = ({
  alias,
  domainStringError,
  onChangeDomainAlias,
}) => {
  // Actions
  const updateDomainAlias = React.useCallback(
    (aliasUpdate: Partial<DomainAlias>) => {
      onChangeDomainAlias({
        ...alias,
        ...aliasUpdate,
      })
    },
    [alias, onChangeDomainAlias],
  )

  // TODO(alex): Handle appropriate display of "unassociated" for status when
  // entering a new domain
  // TODO(alex): Add TXT record display / howto
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          id="domainString"
          label="Domain"
          value={alias.domain}
          error={domainStringError !== undefined}
          helperText={domainStringError}
          onChange={unpackChangeEventValue((updatedDomain) => {
            updateDomainAlias({ domain: updatedDomain })
          })}
          margin="normal"
          fullWidth
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
}

type ToastProps = {
  message: string
  visible: boolean
  onClose: () => void
}
const Toast: FunctionComponent<ToastProps> = ({
  visible,
  message,
  onClose,
}) => {
  return (
    <Snackbar
      open={visible}
      onClose={onClose}
      TransitionComponent={Slide}
      message={message}
    />
  )
}

type DomainVerificationRecordGeneratorProps = {
  firebaseApp: firebase.FirebaseApp
  projectId: string
  disabled?: boolean
}
const DomainVerificationRecordGenerator: FunctionComponent<DomainVerificationRecordGeneratorProps> =
  ({ firebaseApp, projectId, disabled }) => {
    const cssClasses = makeStyles({
      generateNewTXTRecordButton: {
        'background-color': 'white',
      },
    })()

    // Model
    const [recordString, setRecordString] = React.useState<string | undefined>(
      undefined,
    )

    // State
    const [currentlyGenerating, setCurrentlyGenerating] = React.useState(false)
    const [copyToastIsVisible, setCopyToastIsVisible] = React.useState(false)

    // Actions
    const generateNewTXTRecord = async (projectId: string) => {
      // TODO(alex): Store record so the patron doesn't need to keep generating
      // new keys if they are just clicking around the app.
      try {
        const record = await api.requestNewTXTRecord(firebaseApp, projectId)
        setRecordString(record)
      } catch (error) {
        // TODO(alex): Communicate this error to the patron
        console.error(
          'An error occurred when requesting a new verification record token:',
          error,
        )
      }
    }

    const copyTxtRecordToClipboard = async () => {
      if (recordString) {
        await navigator.clipboard.writeText(recordString)
        setCopyToastIsVisible(true)
      }
    }

    // TODO(alex): Handle appropriate display of "unassociated" for status when
    // entering a new domain
    // TODO(alex): Add TXT record display / howto
    return (
      <Grid container spacing={1}>
        {recordString === undefined ? (
          <Grid item xs={12}>
            <Button
              className={cssClasses.generateNewTXTRecordButton}
              disabled={currentlyGenerating || disabled}
              fullWidth
              variant="contained"
              onClick={async () => {
                setCurrentlyGenerating(true)
                await generateNewTXTRecord(projectId)
                setCurrentlyGenerating(false)
              }}>
              {currentlyGenerating ? (
                <>
                  Generating token &nbsp;
                  <CircularProgress size={24} />
                </>
              ) : (
                <>Generate DNS verification token</>
              )}
            </Button>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Tooltip title="Copy this to a TXT record in your DNS provider">
              <TextField
                id="verificationRecord"
                label="DNS verification token"
                inputProps={{
                  style: {
                    textOverflow: 'ellipsis',
                  },
                }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="copy to clipboard"
                        onClick={copyTxtRecordToClipboard}>
                        <FileCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={recordString}
                margin="normal"
                fullWidth
                variant="outlined"
              />
            </Tooltip>
            <Toast
              visible={copyToastIsVisible}
              onClose={() => setCopyToastIsVisible(false)}
              message="DNS verification record copied to clipboard"
            />
          </Grid>
        )}
      </Grid>
    )
  }

type DomainConfigurationInfoPanelProps = {
  alias?: DomainAlias
}
const DomainConfigurationInfoPanel: FunctionComponent<DomainConfigurationInfoPanelProps> =
  ({ alias }) => {
    // TODO(alex): Improve the UI for communicating the status.
    return (
      <>
        {alias === undefined ? (
          <></>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {alias.status === DomainAssociationStatus.associated ? (
                <Typography align="center">
                  Add an <b>A</b> record to DNS for{' '}
                  <a href={`http://${alias.domain}`}>{alias.domain}</a> pointing
                  to the IP address <i>35.201.99.88</i>
                </Typography>
              ) : (
                <Typography align="center">
                  Once <a href={`http://${alias.domain}`}>{alias.domain}</a> is
                  verified (above), add an <b>A</b> record to DNS pointing to
                  the IP address <i>35.201.99.88</i>
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </>
    )
  }

// TODO(alex): Change name to DomainAliasManager
export default CustomDomainManager

import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import { navigate } from 'gatsby'

import firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'

import { Button, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Tooltip from '@material-ui/core/Tooltip'

import {
  DomainAlias,
  DomainAssociationStatus,
  FirestoreStored,
  isError,
  None,
  Option,
  SiteConfig,
  Source,
  SourceType,
  sourceTypeFromUrlProtocol,
  SWITCH_SOURCE_EVENT_TYPE,
  CHANGE_SOURCE_EVENT_TYPE,
  CHANGE_DOMAIN_ALIAS_EVENT_TYPE,
  CHANGE_SOURCE_MANAGER_STATE_EVENT_TYPE,
} from '../utils/types'
import { sanitizePath } from '../utils/helpers'
import * as names from '../utils/names'
import * as api from '../utils/api'
import * as types from '../utils/types'
import * as constants from '../utils/constants'

import SourceManager, { SourceManagerState } from './source-manager'
import CustomDomainManager from './custom-domain-manager'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

/* eslint-disable @typescript-eslint/no-var-requires */
const { siteMetadata }: SiteConfig =
  require('../../gatsby-config.ts') as SiteConfig
/* eslint-enable @typescript-eslint/no-var-requires */

const getProjectServingUri = (projectId: string) => {
  return `http://${projectId}.${siteMetadata.config.projectServingHost}`
}

const validateSourceUrl = (st: SourceType, url: string): Option<Error> => {
  if (st === SourceType.dropbox) {
    if (url === '' || url === constants.DEFAULT_DROPBOX_SOURCE_URL) {
      return None
    } else {
      return new Error('Dropbox source type does not require a source URL')
    }
  }

  // TODO(alex): Actually implement a proper sanitizer/validator
  const trimmed = url.trim()

  const urlSourceType = sourceTypeFromUrlProtocol(trimmed.split(':')?.[0])
  if (isError(urlSourceType)) {
    return new Error(`Unrecognized URL protocol: ${urlSourceType.message}`)
  }

  if (st !== urlSourceType) {
    return new Error(
      `URL protocol source type "${urlSourceType}" does not match ` +
        `desired source type "${st}"`,
    )
  }
  switch (st) {
    case SourceType.public_git_http: {
      if (!trimmed.startsWith('https://')) {
        return new Error('Public git URL must be prefixed with "https://"')
      }
      if (!trimmed.endsWith('.git')) {
        return new Error('Public git URL must end in ".git"')
      }
      break
    }
    case SourceType.private_git_ssh: {
      if (!trimmed.startsWith('ssh://')) {
        return new Error('Private git URL must be prefixed with "ssh://"')
      }
      if (!trimmed.endsWith('.git')) {
        return new Error('Private git URL must end in ".git"')
      }
      break
    }
  }
  return None
}

const validateSubfolderPath = (rawPath: string): Option<Error> => {
  // TODO(alex): Actually implement a proper sanitizer/validator
  const cleanedPath = sanitizePath(rawPath)
  if (cleanedPath !== rawPath) {
    return new Error(`Path was not sanitary: ${rawPath} != ${cleanedPath}`)
  }
  return None
}

const ManageProjectComponentController = ({
  firebase: firebaseApp,
  projectId,
}: ManageProjectProps) => {
  if (!names.identifierIsValid(projectId)) {
    throw new Error(`Project ID is not a valid identifier: ${projectId}`)
  }

  // Model
  const [fsSource, setFsSource] = React.useState<
    FirestoreStored<Source> | undefined
  >(undefined)
  const [domainAlias, setDomainAlias] = React.useState<DomainAlias | undefined>(
    undefined,
  )

  // State
  const [componentLoaded, setComponentLoaded] = React.useState(false)
  const [currentlySyncing, setCurrentlySyncing] = React.useState(false)
  const [sourceManagerState, setSourceManagerState] = React.useState(
    SourceManagerState.viewing,
  )
  const [selectedTab, setSelectedTab] = React.useState(0)

  const [currentlyEditing, setCurrentlyEditing] = React.useState(false)
  React.useEffect(() => {
    setCurrentlyEditing(
      sourceManagerState === SourceManagerState.editing ||
        sourceManagerState === SourceManagerState.saving,
    )
  }, [sourceManagerState])

  // Initialize component
  React.useEffect(() => {
    let isCanceled = false
    const asyncEffect = async (currentProjectId: string) => {
      try {
        let retrievedProject
        try {
          retrievedProject = await api.getProject(firebaseApp, projectId)
        } catch (error) {
          console.error('An occurred when retrieving project:', error)
          return
        }

        let retrievedSource
        try {
          retrievedSource = await api.getProjectPrimarySource(
            firebaseApp,
            projectId,
          )
        } catch (error) {
          console.error('An occurred when retrieving source:', error)
        }

        let retrievedDomainAlias = undefined
        try {
          const domainAliases = retrievedProject.domain_aliases
          const pinnedDomainAlias = retrievedProject.pinned_domain_alias
          if (domainAliases && pinnedDomainAlias) {
            const projectPath = `projects/${projectId}`
            // TODO(alex): Support displaying all aliases instead of just one
            // TODO(alex): Actually retrieve current domain alias instead of
            // faking it here.
            const localStubbedDomainAlias: types.DomainAlias = {
              status: domainAliases[pinnedDomainAlias].status,
              domain: pinnedDomainAlias,
              project: projectPath,
              last_status_update_time: new Date(Date.now()).toISOString(),
            }

            retrievedDomainAlias = localStubbedDomainAlias
          }
        } catch (error) {
          console.error('An occurred when retrieving domain alias:', error)
        }

        // NOTE(alex): If we don't close over `isCanceled`, we won't see the
        // true value for whether the "owning component" has been unmounted yet.
        if (!isCanceled) {
          setDomainAlias(retrievedDomainAlias)

          if (retrievedSource) {
            setFsSource(retrievedSource)
          }

          setComponentLoaded(true)
        }
      } catch (error) {
        console.error(
          `An error was encountered in accessing project ${currentProjectId}, ` +
            'redirecting to account creation flow to try and rectify the ' +
            `issue:`,
          error,
        )
        // It appears this account has a mis-configured project.  Redirect to
        // the account creation flow to try and fix the issue.
        await navigate('/sign-up')
        return
      }
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }
      void asyncEffect(projectId)
    })

    return () => {
      unsubscribe()
      isCanceled = true
    }
  }, [firebaseApp, projectId])

  // Actions
  const syncProject = React.useCallback(async () => {
    if (fsSource === undefined) {
      console.error('Tried to sync but source has not yet been loaded')
      return
    }

    const sourceId = fsSource.path.split('/').pop()
    if (sourceId === undefined) {
      console.error(`Source's Firebase path was invalid: ${fsSource.path}`)
      return
    }

    setCurrentlySyncing(true)

    const requestBody: types.SynchronizationRequestBody = {
      projectId,
      sourceId,
      sourceType: fsSource.value.type,
      sourceUrl: fsSource.value.source_url,
      sourceContentSubfolder: fsSource.value.content_subfolder_path,
      sourceCredentialId: fsSource.value.deploy_credential,
    }
    // TODO(alex): Verify that project data is synchronized
    // TODO(alex): If sourceGitUrl != sourceUrl, create new source
    await api.requestProjectSync(firebaseApp, requestBody)
    setCurrentlySyncing(false)
  }, [firebaseApp, fsSource, projectId])

  const setSourceAsPrimary = React.useCallback(
    async (event: CustomEvent<string>) => {
      if (!event || event.type !== SWITCH_SOURCE_EVENT_TYPE) {
        return
      }

      const sourceId = event.detail
      await api.setSourceAsPrimary(firebaseApp, projectId, sourceId)

      try {
        const retrievedSource = await api.getProjectPrimarySource(
          firebaseApp,
          projectId,
        )
        setFsSource(retrievedSource)
      } catch (error) {
        console.error('An occurred when retrieving source:', error)
      }
    },
    [firebaseApp, projectId],
  )

  const saveSource = React.useCallback(
    async (event: CustomEvent<Source>) => {
      if (!event || event.type !== CHANGE_SOURCE_EVENT_TYPE) {
        return
      }
      const sourceToSave = event.detail

      const sourceUrlValidationResult = validateSourceUrl(
        sourceToSave.type,
        sourceToSave.source_url,
      )
      if (sourceUrlValidationResult !== None) {
        // TODO(alex): Communicate to the user that the URL is invalid
        console.error(sourceUrlValidationResult.message)
        return
      }

      const subfolderPathValidationResult = validateSubfolderPath(
        sourceToSave.content_subfolder_path,
      )
      if (subfolderPathValidationResult !== None) {
        // TODO(alex): Communicate to the user that the path is invalid
        console.error(subfolderPathValidationResult.message)
        return
      }

      if (fsSource === undefined) {
        console.error('Tried to save new source before prior source was loaded')
        return
      }
      // TODO(alex): Test all existing sources on project and not _just_
      //             the last one used.
      const shouldCreateNewSource =
        (!!sourceToSave.source_url &&
          sourceToSave.source_url !== fsSource.value.source_url) ||
        sourceToSave.content_subfolder_path !==
          fsSource.value.content_subfolder_path ||
        sourceToSave.type !== fsSource.value.type

      let updatedSource = fsSource
      if (shouldCreateNewSource) {
        // TODO(alex): Communicate to the user that something is happening.
        try {
          updatedSource = await api.createNewSource(
            firebaseApp,
            projectId,
            sourceToSave,
          )
        } catch (error) {
          // TODO(alex): Communicate failure to user
          console.error(
            'Error occurred while creating new source:',
            error instanceof Error ? error.message : JSON.stringify(error),
          )
        }

        // Delay notification of UI until we're completely done so we don't render
        // partial state.  If we instead setFsSource inside the code above, we
        // might, for example, try to display a new source that requires a deploy
        // key before said deploy key is actually added.
        setFsSource(updatedSource)
      }
    },
    [firebaseApp, fsSource, projectId],
  )

  // TODO(alex): Handle the case where another project is already associated
  // with the desired domain.  This should probably return an error that
  // requires the user to A) dissociate that domain if they are the owner and
  // want to associate it with this project, or B) ask them to contact support
  // if they believe the other domain is incorrectly associated (either its a
  // previous owner or something more nefarious).
  const saveDomainAlias = React.useCallback(
    async (event: CustomEvent<DomainAlias>) => {
      if (!event || event.type !== CHANGE_DOMAIN_ALIAS_EVENT_TYPE) {
        return
      }
      const domainAliasToPropose = event.detail

      if (!names.domainIsValid(domainAliasToPropose.domain)) {
        // TODO(alex): Communicate to the user that the domain is invalid
        console.error(
          'Provided domain was invalid',
          domainAliasToPropose.domain,
        )
        return
      }

      const shouldProposeNewAlias =
        !!domainAliasToPropose.domain &&
        domainAliasToPropose.domain !== domainAlias?.domain

      let updatedDomainAliasProposal = domainAliasToPropose
      if (shouldProposeNewAlias) {
        // TODO(alex): Communicate to the user that something is
        //             happening.

        let receivedDomainAliasProposal
        try {
          receivedDomainAliasProposal = await api.proposeNewDomainAlias(
            firebaseApp,
            projectId,
            updatedDomainAliasProposal.domain,
          )
        } catch (error) {
          // TODO(alex): Communicate failure to user
          console.error(
            'Error occurred while proposing domain alias:',
            error instanceof Error ? error.message : JSON.stringify(error),
          )
          return
        }

        updatedDomainAliasProposal = receivedDomainAliasProposal.value
      }

      // Delay notification of UI until we're completely done so we don't render
      // partial state.  If we instead setFsSource inside the code above, we
      // might, for example, try to display a new source that requires a deploy
      // key before said deploy key is actually added.
      setDomainAlias(updatedDomainAliasProposal)
    },
    [domainAlias, firebaseApp, projectId],
  )

  const renewDomainAliasProposal = React.useCallback(
    async (event: CustomEvent<DomainAlias>) => {
      if (!event || event.type !== CHANGE_DOMAIN_ALIAS_EVENT_TYPE) {
        return
      }
      const domainAliasToPropose = event.detail

      if (!names.domainIsValid(domainAliasToPropose.domain)) {
        // TODO(alex): Communicate to the user that the domain is invalid
        console.error(
          'Provided domain was invalid',
          domainAliasToPropose.domain,
        )
        return
      }

      if (domainAliasToPropose.status === DomainAssociationStatus.associated) {
        return
      }

      let receivedDomainAliasProposal
      try {
        receivedDomainAliasProposal = await api.proposeNewDomainAlias(
          firebaseApp,
          projectId,
          domainAliasToPropose.domain,
        )
      } catch (error) {
        // TODO(alex): Communicate failure to user
        console.error(
          'Error occurred while proposing domain alias:',
          error instanceof Error ? error.message : JSON.stringify(error),
        )
        return
      }

      // Delay notification of UI until we're completely done so we don't render
      // partial state.  If we instead setFsSource inside the code above, we
      // might, for example, try to display a new source that requires a deploy
      // key before said deploy key is actually added.
      setDomainAlias(receivedDomainAliasProposal.value)
    },
    [firebaseApp, projectId],
  )

  const registerSourceManagerState = React.useCallback(
    (event: CustomEvent<SourceManagerState>): Promise<undefined> => {
      if (!event || event.type !== CHANGE_SOURCE_MANAGER_STATE_EVENT_TYPE) {
        return Promise.resolve(undefined)
      }

      setSourceManagerState(event.detail)
      return Promise.resolve(undefined)
    },
    [],
  )

  // ESLint disable necessary as type provided by Material-UI is any
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const selectTab = React.useCallback(
    (event: React.ChangeEvent<unknown>, tabIndex: any) => {
      if (tabIndex === undefined || typeof tabIndex !== 'number') {
        console.error(
          'value passed to selectTab was of an unexpected type:',
          tabIndex,
          typeof tabIndex,
        )
        return
      }

      setSelectedTab(tabIndex)
    },
    [],
  )
  /* eslint-enable @typescript-eslint/no-explicit-any */

  return {
    model: {
      projectId,
      source: fsSource,
      domainAlias,
    },

    state: {
      componentLoaded,
      currentlySyncing,
      currentlyEditing,
      selectedTab,
    },

    actions: {
      syncProject,
      setSourceAsPrimary,
      saveSource,
      saveDomainAlias,
      renewDomainAliasProposal,
      registerSourceManagerState,
      selectTab,
    },
  }
}

type ManageProjectProps = {
  firebase: firebase.FirebaseApp
  projectId: string
}
const ManageProject: FunctionComponent<ManageProjectProps> = (props) => {
  const c = ManageProjectComponentController(props)

  return (
    <>
      <Helmet
        title={`Manage ${c.model.projectId} (${c.model.projectId})`}
        meta={[
          {
            name: 'description',
            content: 'Configure project information.',
          },
          {
            name: 'keywords',
            content: 'website, hosting, admin, dashboard',
          },
        ]}
      />
      <h1>
        Manage project{' '}
        <a href={getProjectServingUri(c.model.projectId)}>
          {c.model.projectId}
        </a>
      </h1>
      {!c.state.componentLoaded || !c.model.source ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <AppBar position="static" color="transparent" elevation={0}>
              <Tabs
                value={c.state.selectedTab}
                onChange={c.actions.selectTab}
                aria-label="simple tabs example">
                <Tab label="Source" {...a11yProps(0)} />
                <Tab label="Domain" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={c.state.selectedTab} index={0}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SourceManager
                    firebaseApp={props.firebase}
                    projectId={c.model.projectId}
                    source={c.model.source}
                    onSwitchSource={c.actions.setSourceAsPrimary}
                    onChangeSource={c.actions.saveSource}
                    onChangeState={c.actions.registerSourceManagerState}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SyncButton
                    currentlySyncing={c.state.currentlySyncing}
                    disabled={c.state.currentlyEditing}
                    onClick={c.actions.syncProject}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={c.state.selectedTab} index={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <CustomDomainManager
                    firebaseApp={props.firebase}
                    projectId={c.model.projectId}
                    domainAlias={c.model.domainAlias}
                    onChangeDomainAlias={c.actions.saveDomainAlias}
                    onRefreshDomainAliasProposal={
                      c.actions.renewDomainAliasProposal
                    }
                  />
                </Grid>
                {/* TODO(alex): Add button for checking on domain verification status*/}
                {/* TODO(alex): If not TXT record is set, display explainer / provide a record to add in case the user has not yet. */}
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      )}
    </>
  )
}

type SyncButtonProps = {
  currentlySyncing: boolean
  disabled: boolean
  onClick: React.MouseEventHandler
}
const SyncButton: FunctionComponent<SyncButtonProps> = ({
  currentlySyncing,
  disabled,
  onClick,
}) => {
  return (
    <Tooltip title="Snapshot the current version and serve it from HostBurro">
      <span>
        {currentlySyncing ? (
          <Button variant="contained" color="primary" disabled={true} fullWidth>
            <CircularProgress size={24} />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            fullWidth
            onClick={onClick}>
            Create New Snapshot
          </Button>
        )}
      </span>
    </Tooltip>
  )
}

export default ManageProject

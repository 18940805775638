import React, { ReactElement, FunctionComponent } from 'react'

import { navigate } from 'gatsby'

import firebase from 'firebase/app'
import { getAuth } from 'firebase/auth'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import NativeSelect from '@material-ui/core/NativeSelect'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ErrorIcon from '@material-ui/icons/Error'

import { getFlags } from '../utils/flags'
import * as api from '../utils/api'
import * as constants from '../utils/constants'
import { unpackChangeEventValue, sanitizePath } from '../utils/helpers'
import { gitRepoUrlIsValid } from '../utils/names'
import {
  SWITCH_SOURCE_EVENT_TYPE,
  CHANGE_SOURCE_EVENT_TYPE,
  CHANGE_SOURCE_MANAGER_STATE_EVENT_TYPE,
  FirestoreStored,
  None,
  Option,
  Source,
  SourceType,
  isError,
  urlProtocolForSourceType,
} from '../utils/types'

import {
  MESSAGE_SOURCE as SourceManagerDropboxAuthReceiver,
  AdminPaneMessage,
} from './source-manager-dropbox-auth-receiver'

const validateSourceUrl = (st: SourceType, url: string): Option<Error> => {
  // TODO(alex): Actually implement a proper sanitizer/validator
  const trimmed = url.trim()

  const genericError = gitRepoUrlIsValid(trimmed)
  if (isError(genericError)) {
    return new Error(`Error: ${genericError.message}`)
  }

  switch (st) {
    case SourceType.dropbox: {
      if (url !== '' && url !== constants.DEFAULT_DROPBOX_SOURCE_URL) {
        return new Error('Dropbox source type does not require a source URL')
      }
      break
    }
    case SourceType.public_git_http: {
      if (!trimmed.startsWith('http')) {
        return new Error(
          'Error: Public git URL must be prefixed with "http://" or "https://"',
        )
      }
      break
    }
    case SourceType.private_git_ssh: {
      if (!trimmed.startsWith('ssh')) {
        return new Error(
          'Error: Private git URL must be prefixed with "ssh://"',
        )
      }
      break
    }
  }

  return None
}

const validateSubfolderPath = (rawPath: string): Option<Error> => {
  // TODO(alex): Actually implement a proper sanitizer/validator
  const cleanedPath = sanitizePath(rawPath)
  if (cleanedPath !== rawPath) {
    return new Error(
      `Path contained unsupported components. Did you mean "${cleanedPath}"?`,
    )
  }
  return None
}

const cloneSource = (oldSource: Source) => {
  const newSource = { ...oldSource }
  delete newSource.deploy_credential
  return newSource
}

const SourceManagerComponentController = ({
  firebaseApp,
  projectId,
  source,
  onSwitchSource,
  onChangeSource,
  onChangeState,
}: SourceManagerProps) => {
  // Model
  const [sshDeployKey, setSshDeployKey] = React.useState<string | undefined>(
    undefined,
  )

  const [dropboxHasBeenAuthorized, setDropboxHasBeenAuthorized] =
    React.useState(false)
  const [dropboxAuthorizationError, setDropboxAuthorizationError] =
    React.useState<undefined | string>(undefined)

  const [newSource, updateNewSource] = React.useReducer(
    (state: Source, update: Partial<Source>) => {
      return {
        ...state,
        ...update,
      }
    },
    cloneSource(source.value),
  )

  const [newPrimarySourceIdSelected, setNewPrimarySourceIdSelected] =
    React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    if (source !== undefined) {
      updateNewSource(cloneSource(source.value))
    }
  }, [source])

  // State
  const [newSourceUrlError, setNewSourceUrlError] = React.useState<
    string | undefined
  >(undefined)
  React.useEffect(() => {
    const err = validateSourceUrl(newSource.type, newSource.source_url)
    if (err !== None) {
      setNewSourceUrlError(err.message)
    } else {
      setNewSourceUrlError(undefined)
    }
  }, [newSource])

  const [newSubfolderPathError, setNewSubfolderPathError] = React.useState<
    string | undefined
  >(undefined)
  React.useEffect(() => {
    const err = validateSubfolderPath(newSource.content_subfolder_path)
    if (err !== None) {
      setNewSubfolderPathError(err.message)
    } else {
      setNewSubfolderPathError(undefined)
    }
  }, [newSource])

  const [formIsValid, setFormIsValid] = React.useState(true)
  React.useEffect(() => {
    if (newSource.type === SourceType.dropbox) {
      setFormIsValid(dropboxHasBeenAuthorized)
    } else {
      setFormIsValid(!newSourceUrlError && !newSubfolderPathError)
    }
  }, [
    newSource,
    dropboxHasBeenAuthorized,
    newSourceUrlError,
    newSubfolderPathError,
  ])

  const [componentLoaded, setComponentLoaded] = React.useState(false)
  const [currentlyEditing, setCurrentlyEditing] = React.useState(false)
  const [currentlySaving, setCurrentlySaving] = React.useState(false)
  const [currentlyLinkingExternalAccount, setCurrentlyLinkingExternalAccount] =
    React.useState(false)

  const [currentState, setCurrentState] = React.useState(
    SourceManagerState.viewing,
  )
  React.useEffect(() => {
    if (currentlySaving) {
      setCurrentState(SourceManagerState.editing)
      return
    }
    if (currentlyEditing) {
      setCurrentState(SourceManagerState.editing)
      return
    }
    setCurrentState(SourceManagerState.viewing)
  }, [currentlyEditing, currentlySaving])

  React.useEffect(() => {
    void onChangeState(
      new CustomEvent(CHANGE_SOURCE_MANAGER_STATE_EVENT_TYPE, {
        detail: currentState,
      }),
    )
  }, [currentState, onChangeState])

  // Initialize component
  React.useEffect(() => {
    let isCanceled = false
    const asyncEffect = async (src: FirestoreStored<Source>) => {
      try {
        let retrievedSshDeployKey: string | undefined
        if (src.value.type === SourceType.private_git_ssh) {
          const tmpDeployKey = source.value.deploy_credential
            ? await api.getSourceSshDeployKey(
                firebaseApp,
                projectId,
                source.id,
                source.value.deploy_credential,
              )
            : None

          if (tmpDeployKey !== None) {
            retrievedSshDeployKey = tmpDeployKey
          } else {
            console.error(
              'There was an error in retrieving the Deploy Key for source',
              source,
            )
          }
        }

        // NOTE(alex): If we don't close over `isCanceled`, we won't see the
        // true value for whether the "owning component" has been unmounted yet.
        if (!isCanceled) {
          setSshDeployKey(retrievedSshDeployKey)
          setComponentLoaded(true)
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : JSON.stringify(error)
        console.error(
          `An error was encountered in accessing source ${JSON.stringify(
            src,
          )}, ` +
            'redirecting to account creation flow to try and rectify the ' +
            `issue: ${errorMessage}`,
        )
        // It appears this account has a mis-configured project.  Redirect to
        // the account creation flow to try and fix the issue.
        await navigate('/sign-up')
        return
      }
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }
      void asyncEffect(source)
    })

    return () => {
      unsubscribe()
      isCanceled = true
    }
  }, [firebaseApp, projectId, source, sshDeployKey])

  const setSourceAsPrimary = React.useCallback(
    async (sourceId: string) => {
      await onSwitchSource(
        new CustomEvent(SWITCH_SOURCE_EVENT_TYPE, {
          detail: sourceId,
        }),
      )
    },
    [onSwitchSource],
  )

  const updatePrimarySource = React.useCallback(
    async (src: Source): Promise<void> => {
      await onChangeSource(
        new CustomEvent(CHANGE_SOURCE_EVENT_TYPE, {
          detail: src,
        }),
      )
    },
    [onChangeSource],
  )

  const linkDropboxAccount = React.useCallback(async (): Promise<void> => {
    let dropboxAuthorizationUrl: string
    try {
      dropboxAuthorizationUrl = await api.mintDropboxAuthorizationUrl(
        firebaseApp,
        projectId,
      )
    } catch (error) {
      // TODO(alex): Set this up to provide a dynamic error with context as
      // to what actually failed.
      // TODO(alex): Make it easy to send feedback with the appropriate
      // extra info attached.
      // TODO(alex): Create unique identifiers for errors so they're easier to
      // communicate.
      setDropboxAuthorizationError(
        'Dropbox failed to link, please try again. ' +
          'If this problem persists, please send feedback.',
      )
      const errorMessage =
        error instanceof Error ? error.message : JSON.stringify(error)
      throw new Error(
        `Failed to get a valid Dropbox authorization URL: ` + `${errorMessage}`,
      )
    }

    return new Promise((resolve, reject) => {
      // console.debug(`Opening popup to Dropbox: ${dropboxAuthorizationUrl}`)
      console.debug('Opening popup to Dropbox')

      let popupResolved = false

      const popup = window.open(dropboxAuthorizationUrl, '_blank')
      if (popup === null) {
        setDropboxAuthorizationError(
          'Popup to link Dropbox could not be launched.',
        )
        reject(new Error('Dropbox OAuth popup could not be opened.'))
        return
      }

      // messageListener is responsible for resolving/rejecting the promise in
      // all cases except where the popup was closed before any message was
      // received.
      const messageListener = (event: MessageEvent) => {
        console.debug('Message received')
        // Verify event came from same app as this page.
        if (event.origin !== window.origin) {
          return
        }

        const message = event.data as AdminPaneMessage

        if (message.source !== SourceManagerDropboxAuthReceiver) {
          return
        }

        const sourceId = message.payload?.sourceId
        if (message.payload?.status === 'success' && sourceId) {
          // TODO(alex): Get contents for this source to populate newSource
          // fields properly before save button can be hit.
          setNewPrimarySourceIdSelected(sourceId)
          console.debug(
            'Dropbox popup successfully resolved to Source ID',
            sourceId,
          )
          resolve()
        } else {
          // TODO(alex): Set this up to provide a dynamic error with context as
          // to what actually failed.
          // TODO(alex): Make it easy to send feedback with the appropriate
          // extra info attached.
          setDropboxAuthorizationError(
            'Dropbox failed to link, please try again. ' +
              'If this problem persists, please send feedback.',
          )
          reject(
            new Error(
              'Dropbox Authz Receiver failed with message:' +
                JSON.stringify(event.data),
            ),
          )
        }

        popupResolved = true
        popup.close()
      }

      window.addEventListener('message', messageListener, false)

      const POPUP_CLOSED_POLL_RATE = 1000 / 4 // 1/4 second
      type callbackType = (callback: callbackType) => void
      // 'unload' event is not reliable, so poll for popup closed state.
      const pollPopupClosed = (callback: callbackType) => {
        if (!popup.closed) {
          // Still waiting for popup to be closed.
          setTimeout(() => callback(callback), POPUP_CLOSED_POLL_RATE)
          return
        }

        console.debug('Dropbox authorization popup closed')
        window.removeEventListener('message', messageListener)
        if (!popupResolved) {
          // TODO(alex): Set this up to provide a dynamic error with context as
          // to what actually failed.
          // TODO(alex): Make it easy to send feedback with the appropriate
          // extra info attached.
          setDropboxAuthorizationError(
            'Dropbox popup closed before authorization was complete. ' +
              'If this problem persists, please send feedback.',
          )
          reject(new Error('Dropbox OAuth popup closed before completion'))
        }
      }

      pollPopupClosed(pollPopupClosed)
    })
  }, [firebaseApp, projectId])

  const startEditing = React.useCallback(() => setCurrentlyEditing(true), [])
  const finishEditing = React.useCallback(() => setCurrentlyEditing(false), [])

  const saveAndFinishEditing = React.useCallback(async () => {
    setCurrentlySaving(true)
    try {
      if (newPrimarySourceIdSelected) {
        if (newSource.content_subfolder_path) {
          // TODO(alex): Figure out a way to update subfolder configuration on
          // initial creation.  The issue as of this TODO was that
          // onSwitchSource and onChangeSource do not affect each other's
          // closures over source value state....  That would require a
          // component reload to rebind, which won't happen within a given
          // function scope.
          console.warn(
            'Setting subfolder not yet supported on initial configuration',
          )
        }
        await setSourceAsPrimary(newPrimarySourceIdSelected)
        setNewPrimarySourceIdSelected(undefined)
      } else {
        await updatePrimarySource(newSource)
      }
      setCurrentlySaving(false)
      finishEditing()
    } catch (error) {
      // TODO(alex): Communicate this failure to the user.
      console.error('An error occurred while saving edits:', error)
    }

    setDropboxAuthorizationError(undefined)
  }, [
    finishEditing,
    newPrimarySourceIdSelected,
    newSource,
    setSourceAsPrimary,
    updatePrimarySource,
  ])

  return {
    model: {
      projectId,
      source,
      newSource,
      sshDeployKey,
    },

    state: {
      dropboxHasBeenAuthorized,
      dropboxAuthorizationError,
      newSourceUrlError,
      newSubfolderPathError,
      formIsValid,
      componentLoaded,
      currentlyEditing,
      currentlySaving,
      currentlyLinkingExternalAccount,
    },

    actions: {
      updateNewSource,
      updatePrimarySource,
      linkDropboxAccount: async (): Promise<void> => {
        console.debug('Linking dropbox account')
        try {
          setCurrentlyLinkingExternalAccount(true)
          await linkDropboxAccount()
          setDropboxHasBeenAuthorized(true)
          setDropboxAuthorizationError(undefined)
          console.debug('Finished without exception')
        } catch (error) {
          console.error('Error occurred while linking Dropbox account:', error)
        } finally {
          setCurrentlyLinkingExternalAccount(false)
        }
      },
      startEditing,
      finishEditing,
      saveAndFinishEditing,
    },
  }
}

export enum SourceManagerState {
  viewing,
  editing,
  saving,
}

type SourceManagerProps = {
  firebaseApp: firebase.FirebaseApp
  projectId: string
  source: FirestoreStored<Source>
  onSwitchSource: (event: CustomEvent) => Promise<void>
  onChangeSource: (event: CustomEvent) => Promise<void>
  onChangeState: (event: CustomEvent) => Promise<void>
}
const SourceManager: FunctionComponent<SourceManagerProps> = (props) => {
  // TODO(alex): Surface a hook to notify the parent when we're finished
  // loading.
  const c = SourceManagerComponentController(props)

  const cssClasses = makeStyles({
    sourceCard: {
      width: '100%',
    },
    sourceCardTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    cancelButton: {
      backgroundColor: 'white',
    },
  })()

  return (
    <>
      {!c.state.componentLoaded ? (
        <CircularProgress />
      ) : (
        <Card
          className={cssClasses.sourceCard}
          elevation={c.state.currentlyEditing ? 10 : 0}>
          <CardHeader
            action={
              <IconButton
                aria-label="edit"
                disabled={c.state.currentlyEditing}
                onClick={c.actions.startEditing}>
                <EditIcon />
              </IconButton>
            }
            title={
              c.state.currentlyEditing
                ? 'Editing Project Source'
                : 'Project Source'
            }
          />

          <CardContent>
            {c.state.currentlySaving ? (
              <>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={'auto'}>
                    <Typography>Saving Source</Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" spacing={1}>
                  <Grid item xs={'auto'}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {c.state.currentlyEditing ? (
                  <SourceEditor
                    source={c.model.newSource}
                    sourceUrlError={c.state.newSourceUrlError}
                    sourceContentSubfolderPathError={
                      c.state.newSubfolderPathError
                    }
                    dropboxHasBeenAuthorized={c.state.dropboxHasBeenAuthorized}
                    dropboxAuthorizationError={
                      c.state.dropboxAuthorizationError
                    }
                    linkDropboxAccount={c.actions.linkDropboxAccount}
                    currentlyLinkingAccount={
                      c.state.currentlyLinkingExternalAccount
                    }
                    sshDeployKey={c.model.sshDeployKey}
                    onChangeSource={c.actions.updateNewSource}
                  />
                ) : (
                  <>
                    {c.model.source.value.type === SourceType.private_git_ssh &&
                    !c.model.sshDeployKey ? (
                      <>
                        {/* This branch is achieved when we first change source
                            type to private_git_ssh but before we've retrieved
                            the deploy key from the backend. */}
                        <CircularProgress />
                      </>
                    ) : (
                      <SourceDisplay
                        source={c.model.source.value}
                        sshDeployKey={c.model.sshDeployKey}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </CardContent>

          {c.state.currentlyEditing ? (
            <Grow
              in={c.state.currentlyEditing}
              style={{ transformOrigin: '0 0 0' }}>
              <CardActions>
                <Button
                  className={cssClasses.cancelButton}
                  variant="contained"
                  disabled={c.state.currentlySaving}
                  onClick={c.actions.finishEditing}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={c.state.currentlySaving || !c.state.formIsValid}
                  onClick={c.actions.saveAndFinishEditing}>
                  Save
                </Button>
              </CardActions>
            </Grow>
          ) : (
            <></>
          )}
        </Card>
      )}
    </>
  )
}

type SourceDisplayProps = {
  source: Source
  // TODO(alex): Consider wrapping the source in some enum with associate values
  sshDeployKey: string | undefined
}
const SourceDisplay: FunctionComponent<SourceDisplayProps> = ({
  source,
  sshDeployKey,
}) => {
  switch (source.type) {
    case SourceType.public_git_http: {
      return (
        <SourceDisplayGitHttp
          gitUrl={source.source_url}
          subfolderPath={source.content_subfolder_path}
        />
      )
    }
    case SourceType.private_git_ssh: {
      const deployKey: string = sshDeployKey || ''
      if (deployKey === '') {
        // TODO(alex): Log this frontend error
        // TODO(alex): Figure out a better / more graceful way to display this
        // error to the user that's actually resolvable.
        console.error('Deploy key is empty and should not be.')
        return <>An error occurred with Deploy Key display.</>
      }
      return (
        <SourceDisplayGitSsh
          gitUrl={source.source_url}
          deployKey={deployKey}
          subfolderPath={source.content_subfolder_path}
        />
      )
    }
    case SourceType.dropbox: {
      return (
        <SourceDisplayDropbox subfolderPath={source.content_subfolder_path} />
      )
    }
  }
  return <>Source type was not recognized.</>
}

type SourceDisplayDropboxProps = {
  subfolderPath?: string
}
const SourceDisplayDropbox: FunctionComponent<SourceDisplayDropboxProps> = ({
  subfolderPath,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {/* TODO(alex): Add a name for which dropbox account so we can sanely
          communicate which account they have linked with this project. */}
        <Typography>Linked to Dropbox</Typography>
      </Grid>
      {subfolderPath ? (
        <Grid item xs={12}>
          <TextField
            id="subfolderPath"
            label="Relative path containing files to serve"
            InputProps={{
              readOnly: true,
            }}
            value={subfolderPath}
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

type SourceDisplayGitHttpProps = {
  gitUrl: string
  subfolderPath?: string
}
const SourceDisplayGitHttp: FunctionComponent<SourceDisplayGitHttpProps> = ({
  gitUrl,
  subfolderPath,
}) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          id="gitUrl"
          label="Git URL for project files"
          InputProps={{
            readOnly: true,
          }}
          value={gitUrl}
          margin="normal"
          fullWidth
          variant="outlined"
        />
      </Grid>
      {subfolderPath ? (
        <Grid item xs={12}>
          <TextField
            id="subfolderPath"
            label="Relative path containing files to serve"
            InputProps={{
              readOnly: true,
            }}
            value={subfolderPath}
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  )
}

type SourceDisplayGitSshProps = {
  gitUrl: string
  deployKey: string
  subfolderPath?: string
}
const SourceDisplayGitSsh: FunctionComponent<SourceDisplayGitSshProps> = ({
  gitUrl,
  deployKey,
  subfolderPath,
}) => {
  const cssClasses = makeStyles({
    deployKeyContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  })()

  // TODO(alex): Have a collapsed form of the SSH key as it's pretty massive by
  // default now when you probably aren't interested in interacting with it.
  return (
    <Grid item container spacing={1}>
      <Grid item xs={12}>
        <TextField
          id="gitUrl"
          label="Git URL for project files"
          InputProps={{
            readOnly: true,
          }}
          value={gitUrl}
          margin="normal"
          fullWidth
          variant="outlined"
        />
      </Grid>
      {subfolderPath ? (
        <Grid item xs={12}>
          <TextField
            id="subfolderPath"
            label="Relative path containing files to serve"
            InputProps={{
              readOnly: true,
            }}
            value={subfolderPath}
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12} className={cssClasses.deployKeyContainer}>
        <TextField
          multiline
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          label="SSH Deploy Key"
          value={deployKey}
        />
      </Grid>
    </Grid>
  )
}

const tryRewritingUrlForSourceType = (url: string, type: SourceType) => {
  const newProtocol = urlProtocolForSourceType(type)
  if (isError(newProtocol)) {
    console.warn(
      'Error encountered when trying to rewrite url protocol',
      newProtocol.message,
    )
    return url
  }

  if (type === SourceType.dropbox) {
    return ''
  }

  return [newProtocol, ...url.split(':').slice(1)].join(':')
}

type SourceEditorProps = {
  source: Source
  sourceUrlError?: string
  sourceContentSubfolderPathError?: string
  onChangeSource: (src: Source) => void

  sshDeployKey?: string

  dropboxHasBeenAuthorized: boolean
  linkDropboxAccount: () => Promise<void>
  currentlyLinkingAccount: boolean
  dropboxAuthorizationError?: string
}

const SourceEditor: FunctionComponent<SourceEditorProps> = ({
  source,
  sourceUrlError,
  sourceContentSubfolderPathError,
  dropboxHasBeenAuthorized,
  dropboxAuthorizationError,
  linkDropboxAccount,
  currentlyLinkingAccount,
  sshDeployKey,
  onChangeSource,
}) => {
  // Actions
  const updateSource = React.useCallback(
    (src: Partial<Source>) => {
      onChangeSource({
        ...source,
        ...src,
      })
    },
    [onChangeSource, source],
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="project-source-type">Type of Source</InputLabel>
          <NativeSelect
            inputProps={{
              name: 'sourceType',
              id: 'project-source-type',
            }}
            value={source.type}
            onChange={unpackChangeEventValue((st) => {
              if (typeof st === 'string' && st in SourceType) {
                const type = st as SourceType
                updateSource({
                  source_url: tryRewritingUrlForSourceType(
                    source.source_url,
                    type,
                  ),
                  type,
                })
              }
            })}>
            {getFlags().enableDropboxSource ? (
              <option value={SourceType.dropbox}>Dropbox</option>
            ) : (
              <></>
            )}
            <option value={SourceType.public_git_http}>
              Public git (HTTP(S))
            </option>
            <option value={SourceType.private_git_ssh}>
              Private git (SSH)
            </option>
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {selectSourceEditorForm(source.type, {
          currentlyLinkingAccount,
          subfolderPath: source.content_subfolder_path || '',
          subfolderPathError: sourceContentSubfolderPathError,
          onUpdateSubfolderPath: unpackChangeEventValue((subfolderPath) => {
            updateSource({ content_subfolder_path: subfolderPath })
          }),

          dropboxHasBeenAuthorized,
          dropboxAuthorizationError,
          linkDropboxAccount,

          gitUrl: source.source_url || '',
          gitUrlError: sourceUrlError,
          onUpdateGitUrl: unpackChangeEventValue((gitUrl) => {
            updateSource({ source_url: gitUrl })
          }),

          sshDeployKey: sshDeployKey,
        })}
      </Grid>
    </Grid>
  )
}

const selectSourceEditorForm = (
  st: SourceType,
  props: SourceEditorFormProps,
): ReactElement => {
  switch (st) {
    case SourceType.private_git_ssh: {
      return (
        <SourceEditorFormGitSsh
          gitUrl={props.gitUrl}
          gitUrlError={props.gitUrlError}
          onUpdateGitUrl={props.onUpdateGitUrl}
          subfolderPath={props.subfolderPath}
          subfolderPathError={props.subfolderPathError}
          onUpdateSubfolderPath={props.onUpdateSubfolderPath}
          sshDeployKey={props.sshDeployKey}
        />
      )
    }
    case SourceType.public_git_http: {
      return (
        <SourceEditorFormGitHttp
          gitUrl={props.gitUrl}
          gitUrlError={props.gitUrlError}
          onUpdateGitUrl={props.onUpdateGitUrl}
          subfolderPath={props.subfolderPath}
          subfolderPathError={props.subfolderPathError}
          onUpdateSubfolderPath={props.onUpdateSubfolderPath}
        />
      )
    }
    case SourceType.dropbox: {
      return (
        <SourceEditorFormDropbox
          currentlyLinkingAccount={props.currentlyLinkingAccount}
          dropboxHasBeenAuthorized={props.dropboxHasBeenAuthorized}
          linkDropboxAccount={props.linkDropboxAccount}
          dropboxAuthorizationError={props.dropboxAuthorizationError}
          subfolderPath={props.subfolderPath}
          subfolderPathError={props.subfolderPathError}
          onUpdateSubfolderPath={props.onUpdateSubfolderPath}
        />
      )
    }
  }
  console.error('Source type component form unknown, rendering nothing.')
  return <></>
}

type SubfolderManagementProps = {
  subfolderPath: string
  subfolderPathError?: string
  onUpdateSubfolderPath: React.ChangeEventHandler<HTMLInputElement>
}

type GitSourceProps = {
  gitUrl: string
  gitUrlError?: string
  onUpdateGitUrl: React.ChangeEventHandler<HTMLInputElement>
}

type SshSourceProps = {
  sshDeployKey?: string
}

type DropboxSourceProps = {
  dropboxHasBeenAuthorized: boolean
  currentlyLinkingAccount: boolean
  linkDropboxAccount: () => Promise<void>
  dropboxAuthorizationError?: string
}

type SourceEditorFormProps = SubfolderManagementProps &
  GitSourceProps &
  SshSourceProps &
  DropboxSourceProps

type DropboxSourceEditorFormProps = DropboxSourceProps &
  SubfolderManagementProps
type GitHttpSourceEditorFormProps = GitSourceProps & SubfolderManagementProps
type GitSshSourceEditorFormProps = GitSourceProps &
  SshSourceProps &
  SubfolderManagementProps

const SourceEditorFormDropboxController = ({
  currentlyLinkingAccount,
  dropboxAuthorizationError,
  dropboxHasBeenAuthorized,
  onUpdateSubfolderPath,
  linkDropboxAccount,
  subfolderPath,
  subfolderPathError,
}: DropboxSourceEditorFormProps) => {
  const linkDropbox = React.useCallback(() => {
    linkDropboxAccount().catch((reason) => {
      console.error('LinkDropbox failed:', reason)
    })
  }, [linkDropboxAccount])

  return {
    model: {
      subfolderPath,
    },
    state: {
      currentlyLinkingAccount,
      subfolderPathError,
      dropboxHasBeenAuthorized,
      dropboxAuthorizationError,
    },
    actions: {
      onUpdateSubfolderPath,
      linkDropbox,
    },
  }
}

const SourceEditorFormDropbox: FunctionComponent<DropboxSourceEditorFormProps> =
  (props) => {
    const c = SourceEditorFormDropboxController(props)
    const cssClasses = makeStyles((theme) => {
      return {
        errorContainer: {
          display: 'flex',
          alignItems: 'center',
          fontSize: '18px',
          color: theme.palette.error.main,
        },
        errorIcon: {
          marginRight: '8px',
        },
      }
    })()

    return (
      <Grid container spacing={1}>
        {getFlags().enableDropboxSourceSubfolder ? (
          <Grid item xs={12}>
            <TextField
              id="subfolderPath"
              label="Relative path containing files to serve"
              onChange={c.actions.onUpdateSubfolderPath}
              value={c.model.subfolderPath}
              error={c.state.subfolderPathError !== undefined}
              helperText={c.state.subfolderPathError}
              margin="normal"
              fullWidth
              variant="outlined"
            />
          </Grid>
        ) : (
          <></>
        )}
        {c.state.dropboxHasBeenAuthorized ? (
          <></>
        ) : (
          <>
            {c.state.dropboxAuthorizationError ? (
              <>
                <Grid item xs={12} className={cssClasses.errorContainer}>
                  <ErrorIcon className={cssClasses.errorIcon} />
                  Dropbox authorization failed:{' '}
                  {c.state.dropboxAuthorizationError}
                </Grid>
              </>
            ) : (
              <></>
            )}

            {c.state.currentlyLinkingAccount ? (
              <Grid item xs={'auto'}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={c.actions.linkDropbox}
                  fullWidth>
                  Link Dropbox to Hostburro
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    )
  }

const SourceEditorFormGitHttp: FunctionComponent<GitHttpSourceEditorFormProps> =
  ({
    gitUrl,
    gitUrlError,
    onUpdateGitUrl,
    subfolderPath,
    subfolderPathError,
    onUpdateSubfolderPath,
  }) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="gitUrl"
            label="Git URL for project files"
            onChange={onUpdateGitUrl}
            value={gitUrl}
            error={gitUrlError !== undefined}
            helperText={gitUrlError}
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="subfolderPath"
            label="Relative path containing files to serve"
            onChange={onUpdateSubfolderPath}
            value={subfolderPath}
            error={subfolderPathError !== undefined}
            helperText={subfolderPathError}
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </Grid>
      </Grid>
    )
  }

const SourceEditorFormGitSsh: FunctionComponent<GitSshSourceEditorFormProps> =
  ({
    gitUrl,
    gitUrlError,
    onUpdateGitUrl,

    subfolderPath,
    subfolderPathError,
    onUpdateSubfolderPath,

    sshDeployKey,
  }) => {
    const cssClasses = makeStyles({
      deployKeyContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      deployKeyButton: {},
    })()

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="gitUrl"
            label="Git URL for project files"
            onChange={onUpdateGitUrl}
            value={gitUrl}
            error={gitUrlError !== undefined}
            helperText={gitUrlError}
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="subfolderPath"
            label="Relative path containing files to serve"
            onChange={onUpdateSubfolderPath}
            value={subfolderPath}
            error={subfolderPathError !== undefined}
            helperText={subfolderPathError}
            margin="normal"
            fullWidth
            variant="outlined"
          />
        </Grid>

        <>
          {sshDeployKey ? (
            <>
              <Grid item xs={12} className={cssClasses.deployKeyContainer}>
                <TextField
                  multiline
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  label="SSH Deploy Key"
                  value={sshDeployKey}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                An SSH Deploy Key will be generated on save
              </Grid>
            </>
          )}
        </>
      </Grid>
    )
  }

export default SourceManager

import firebase from 'firebase/app'

import React, { FunctionComponent } from 'react'

import { WindowLocation, Router } from '@reach/router'

import DefaultLayout from '../layouts/index'

import AccountSummary from '../components/account-summary'
import FirebaseLoadable from '../components/firebase-loadable'
import ManageProject from '../components/manage-project'
import PrivateRoute from '../components/private-route'

type AdminProps = {
  location: WindowLocation
}

const Admin: FunctionComponent<AdminProps> = ({ location }) => {
  let resolver
  let rejecter
  const firebasePromise = new Promise<firebase.FirebaseApp>(
    (resolve, reject) => {
      resolver = resolve
      rejecter = reject
    },
  )
  const [firebaseApp, setFirebaseApp] = React.useState<
    firebase.FirebaseApp | undefined
  >(undefined)

  firebasePromise
    .then((fbApp: firebase.FirebaseApp) => {
      setFirebaseApp(fbApp)
    })
    .catch((reason) => {
      console.error(reason)
    })

  return (
    <DefaultLayout location={location} firebase={firebaseApp}>
      <FirebaseLoadable resolver={resolver} rejecter={rejecter} />
      {!firebaseApp ? (
        <div>Initializing Firebase...</div>
      ) : (
        <Router basepath="/admin">
          <PrivateRoute
            location={location}
            path="/"
            firebase={firebaseApp}
            component={AccountSummary}
          />
          <PrivateRoute
            location={location}
            path="projects/:projectId"
            firebase={firebaseApp}
            component={ManageProject}
          />
        </Router>
      )}
    </DefaultLayout>
  )
}

export default Admin

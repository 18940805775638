import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import firebase from 'firebase/app'
import { getAuth, User as FirebaseUser } from 'firebase/auth'

import Link from 'gatsby-link'
import { navigate } from 'gatsby'

import CircularProgress from '@material-ui/core/CircularProgress'

import * as api from '../utils/api'
import * as types from '../utils/types'

type AccountSummaryProps = {
  firebase: firebase.FirebaseApp
}

const AccountSummary: FunctionComponent<AccountSummaryProps> = ({
  firebase: firebaseApp,
}) => {
  const [componentLoaded, setComponentLoaded] = React.useState(false)

  const [projectIdList, setProjectIdList] = React.useState<string[]>([])

  const isMounted = React.useRef(true)
  React.useEffect(() => {
    const asyncEffect = async (currentUser: FirebaseUser) => {
      if (!currentUser) {
        console.warn('No user authenticated')
        return
      }

      let accountId
      try {
        // TODO(alex): Handle multiple accounts.
        accountId = await api.getAccountId(firebaseApp)
      } catch (error) {
        console.debug('An error occurred while getting account ID', error)
        await navigate('/sign-up')
        return
      }

      if (!accountId || accountId === types.None) {
        await navigate('/sign-up')
        return
      }

      let projectIds
      try {
        projectIds = await api.getProjectIdsForAccount(firebaseApp, accountId)
      } catch (error) {
        console.error(
          'An error occurred while getting projects for patron account:',
          error,
        )
        return
      }

      if (isMounted.current) {
        setProjectIdList(projectIds)
        setComponentLoaded(true)
      }
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged((user) => {
      if (!user) {
        console.warn('No user authenticated')
        return
      }
      void asyncEffect(user)
    })

    return () => {
      unsubscribe()
      isMounted.current = false
    }
  }, [firebaseApp])

  // TODO(alex): Add support here to
  //    1) Update payment information
  //    2) Add collaborators
  //    3) Get to settings (where one could administer contact email, etc.)
  //    4) View basic information related to subscription, including traffic
  //       and storage charged for.
  return (
    <>
      <Helmet
        title={'Manage Projects'}
        meta={[
          {
            name: 'description',
            content: 'Configure project information.',
          },
          {
            name: 'keywords',
            content: 'website, hosting, admin, dashboard',
          },
        ]}
      />
      {!componentLoaded ? (
        <CircularProgress />
      ) : (
        <div>
          <h1>{'Account Overview'}</h1>
          <h2>{'Manage Projects'}</h2>
          <ul>
            {projectIdList.map((projectId) => {
              return (
                <li key={projectId}>
                  <Link to={`projects/${projectId}`}>Project {projectId}</Link>
                </li>
              )
            })}
          </ul>
          <h2>{'Account Info'}</h2>
          <ul>
            <li>
              <Link to="/billing">Billing</Link>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default AccountSummary
